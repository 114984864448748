@import url(/node_modules/react-grid-layout/css/styles.css);
@import url(/node_modules/react-resizable/css/styles.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#content {
    width: 100%;
}

.react-grid-layout {
    /* loading space background */
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.react-grid-item {
    background: #ccc;
    border: 2px solid black;
    box-sizing: border-box;
    cursor: -webkit-grab;
    cursor: grab;
}
.react-grid-item:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.react-grid-item:not(.react-grid-placeholder) {
}

.react-grid-item.resizing {
    opacity: 0.9;
}

.react-grid-item.static {
    background: #cce;
}

.react-grid-item .text {
    font-size: 12px;
    text-align: center;
    position: absolute;
    font-weight: 600;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
}

.react-grid-item .minMax {
    font-size: 12px;
}

.react-grid-item .add {
    cursor: pointer;
}

.react-grid-dragHandleExample {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
}

.toolbox {
    background-color: #dfd;
    width: 100%;
    /* height: 120px; */
    overflow: scroll;
}

.hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
}

.toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
}

.toolbox__items {
    display: block;
}

.toolbox__items__item {
    display: inline-block;
    text-align: center;
    /* line-height: 40px; */
    cursor: pointer;
    width: 10px;
    height: 10px;
    /* padding: 10px; */
    /* margin: 5px; */
    border: 1px solid black;
    background-color: #ddd;
}

.droppable-element {
    /* width: 150px; */
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
